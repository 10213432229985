import React, { useState, useRef , useContext } from "react";
import { createAudioMeter, volumeAudioProcess } from "../Components/Audio/volume-meter"
import { FanEngageContext } from "../Auth/fanengage";

function useAudioLevel() {

  const { onUserSound} = useContext(FanEngageContext);

  const [percent, setPercent] = useState(0);

  var audioContext = null;
  var meter = null;
  var mediaStreamSource = null;
  var canvasContext = null;
  var context = null;
  var soundLevelDiv = null;
  var WIDTH = 400;
  var HEIGHT = 50;
  var rafID = null;

  async function mainAudio(value) {

    // grab our canvas
    canvasContext = document.getElementById("meter")
    context = canvasContext.getContext('2d');
    soundLevelDiv = document.getElementById("soundLevel");
    
    // monkeypatch Web Audio
    //window.AudioContext = window.AudioContext || window.webkitAudioContext;

    // grab an audio context
    //audioContext = new AudioContext();
    // audioContext = new (window.AudioContext || window.webkitAudioContext)()
    // audioContext = new AudioContext();

    // Attempt to get audio input
    // try {
    //   // monkeypatch getUserMedia
    //   navigator.getUserMedia =
    //     navigator.getUserMedia ||
    //     navigator.webkitGetUserMedia ||
    //     navigator.mozGetUserMedia; 
      
    //     // ask for an audio input
    //     navigator.getUserMedia(
    //       { audio: true }, onMicrophoneGranted, onMicrophoneDenied);
      
    // } catch (e) {
    //   console.log('getUserMedia threw exception :' + e);
    // }
    // console.log(value);

  // if(value == false){
  //   if(audioContext !=null){
  //     audioContext.close();
  //   }
  //   return;
  // }
  audioContext = new AudioContext();
	navigator.mediaDevices.getUserMedia({ audio: true })
	.then(stream => {
		mediaStreamSource = audioContext.createMediaStreamSource(stream);
    meter = createAudioMeter(audioContext)
    mediaStreamSource.connect(meter)
		const analyser = audioContext.createAnalyser();
		analyser.fftSize = 2048;
		mediaStreamSource.connect(analyser);
		const dataArray = new Float32Array(analyser.frequencyBinCount);
    if(value == false){
      stream.getAudioTracks()[0].stop();
    }

		function updateSoundLevel() {
			analyser.getFloatTimeDomainData(dataArray);
			let rms = 0;
			for (let i = 0; i < dataArray.length; i++) {
				rms += dataArray[i] * dataArray[i];
			}
			rms = Math.sqrt(rms / dataArray.length);
			let decibel = Math.abs(120 -(-20 * Math.log10(rms)));
      // console.log(decibel)
      if(value == true){
      let soundrec = Math.floor(decibel);
      onUserSound(soundrec);
			soundLevelDiv.innerText = decibel < 30 ? 30 + "db" : "" + decibel.toFixed(2) + " dB";
      }
      if(value == false){
        onUserSound(0);
        soundLevelDiv.innerText = "";
        }
			requestAnimationFrame(updateSoundLevel);
      onLevelChange(decibel);
		}
		updateSoundLevel();
	})
	.catch(err => {
		console.log("Error: " + err);
	});

  }

  function onMicrophoneDenied() {
    console.log("onMicrophoneDenied")
  }


  // function onMicrophoneGranted(stream) {
  //   // Create an AudioNode from the stream.
  //   mediaStreamSource = audioContext.createMediaStreamSource(stream);
  //   meter = createAudioMeter(audioContext)
  //   mediaStreamSource.connect(meter)
  //   const analyser = audioContext.createAnalyser();
  //   analyser.fftSize = 2048;
  //   mediaStreamSource.connect(analyser);
  //   const dataArray = new Float32Array(analyser.frequencyBinCount);

  //   function updateSoundLevel() {
  //     analyser.getFloatTimeDomainData(dataArray);
  //     let rms = 0;
  //     for (let i = 0; i < dataArray.length; i++) {
  //       rms += dataArray[i] * dataArray[i];
  //     }
  //     rms = Math.sqrt(rms / dataArray.length);
  //     let decibel = 20 * Math.log10(rms);
  //     soundLevelDiv.innerText = "" + decibel.toFixed(2) + " dB";
  //     onLevelChange(decibel);
  //     // set up the next visual callback
  //     rafID = window.requestAnimationFrame(updateSoundLevel);
    
  //   }
  //   updateSoundLevel();
  // }

  // function onLevelChange(decibel) {
  //   // clear the background
  //   canvasContext.clearRect(0, 0, WIDTH, HEIGHT);

  //   // check if we're currently clipping
  //   if (meter.checkClipping())
  //     canvasContext.fillStyle = "red";
  //   else
  //     canvasContext.fillStyle = "green";

    
  //   // draw a bar based on the current volume
  //   setPercent(meter.volume * WIDTH * 1.4, HEIGHT);
  //   canvasContext.fillRect(0, 0, Math.abs(decibel) / 100 * WIDTH , HEIGHT);
    
  // }

  function onLevelChange(decibel) {
    context.clearRect(0,0, canvasContext.width,canvasContext.height);

    const centerX = canvasContext.width / 2;
    const centerY = canvasContext.height / 2;
    const radius = 120;

    context.beginPath();
    context.arc(centerX,centerY,Math.abs(decibel) / 120 * radius, 0, 2 * Math.PI, false);
    if(decibel <= 50){
      context.fillStyle = '#51AA36';
    }
    if(decibel >= 51 && decibel <= 80){
      context.fillStyle = '#F4E212';
    }
    if(decibel >= 81 && decibel <= 105){
      context.fillStyle = '#F1930A';
    }
    if(decibel >= 106 && decibel <= 120){
      context.fillStyle = '#EC5616';
    }
    context.fill();
    context.lineWidth = 5;
    context.strokeStyle = 'rgba(255, 255, 255, 0.1)';
    context.stroke();
  }


  function close() {
    window.cancelAnimationFrame(rafID);
  }

  return mainAudio;
}
export default useAudioLevel;