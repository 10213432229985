import React from "react";
import './otp.css';
import { useState, useEffect, useContext } from "react";

import { url , clientid } from "../../constants";
import { OTP_SIGNIN } from "../../Api";
import { AuthContext } from "../../Auth/Auth";

import firebaseConfig from "../../config";
// import 'firebase/compat/auth';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import { CImage } from "@coreui/react";
import Starsportslogo from '../../images/starlogo.png';
import HabbaLogo from '../../images/habbalogo.png'
import Fanplaylogo from '../../images/fanplaybig.png';
import Olympicslogo from '../../images/olympicslogo.png'
import axios from "axios";
import Timer from "react-compound-timer/build"


function Olympicsverification () {

    const {loclat , loclong , otpname,olympicsotpmobileSignIn,otpMobileNo} = useContext(AuthContext)
    const [otpcode, setOtpcode] = useState('')
    const [resendOTP, setResendOTP] = useState(false);


    const handleReset = () => {
        setResendOTP(true);
    };


    if(!window.recaptchaVerifier){
        window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                onSignInSubmit();
            }
        }, getAuth(firebaseConfig));
        }
        window.recaptchaVerifier.render();

        const onSignInSubmit = () => {
            console.log("onSignInSubmit");
        }


    const toResendOtp = () => {
        try{
            if( otpMobileNo.length > 0){
            let phoneNumber = '+91' + otpMobileNo;
            // console.log(phoneNumber , userName)
            const appVerifier = window.recaptchaVerifier;
            const auth = getAuth();
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // console.log(confirmationResult);
                window.confirmationResult = confirmationResult;
                setResendOTP(false);
            })
            .catch((error) => {
                alert(error.message);
            })
            }
        }catch (e){
            console.log(e)
        }
    }


    const confirmOtpcode = () => {
        try{
            if(otpcode.length == 0){
                alert("Please Enter OTP Number")
            }
            else{
        window.confirmationResult.confirm(otpcode).then((result) => {
            // User signed in successfully.
            const user = result.user;
            // console.log(user);
            if(user && user !== null){
                olympicsotpmobileSignIn(user);
            }
            // ...
          }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            alert("Wrong OTP")
            console.log(error);
          });
        }
        }catch (e){
            console.log(e)
        }
    }


    return(
        <div className="olympicverificationscreen">

            <div className="toplogos-verification">
                <CImage src={Olympicslogo} className="starlogo-verification"/>
            </div>

            <div className="olympicsverificationlogin-txthdng">
                <p className="olympicsloginwith-verificationtxt">LOG IN WITH OTP</p>
            </div> 

            <div className="verifying-numberhdng">
                <p className="olympicsverifyyournumber-txt">Verifying your Number!</p>
                <p className="olympicsotpsended-number">We have sent an OTP to your number <span style={{color:"#D6C278"}}>+91 **** ****{otpMobileNo.slice(8,10)}</span></p>
            </div>

            {/* <div className="otpcontainer">
                <div className="otpnumber-tperinput">
                    <input type="number" maxLength="1" className="inputforotp-number"/>
                    <input type="number" maxLength="1" className="inputforotp-number"/>
                    <input type="number" maxLength="1" className="inputforotp-number"/>
                    <input type="number" maxLength="1" className="inputforotp-number"/>
                    <input type="number" maxLength="1" className="inputforotp-number"/>
                    <input type="number" maxLength="1" className="inputforotp-number"/>
                </div>
            </div> */}

            <div className="numberinputsfor-otpverification">
                <div className="olympicsverification-inputui">
                    <input 
                    type="number"
                    placeholder="_ _ _  _ _ _"
                    required
                    contentEditable
                    onChange={(e) => setOtpcode(e.target.value)} 
                    className="olympicsnumberinputboxverify"/>

                    <div className="timerandreser-verification">
                        {resendOTP ? 
                        <p className="olympicsresendotp-txt" onClick={toResendOtp}>Resend OTP?</p>
                        :
                         
                         <Timer
                         initialTime={60000}
                         direction="backward"
                         timeToUpdate={10}
                         checkpoints={[
                            {
                                time:0,
                                callback:() => {handleReset();},
                            },
                         ]}
                         >
                            <p className="olympicsverification-timer"><Timer.Minutes />:<Timer.Seconds/></p>
                         </Timer>
                        }
                        {/* <p className="verification-timer">20:30</p> */}
                        {/* <p className="resendotp-txt" onClick={toResendOtp}>Resend OTP?</p> */}
                    </div>
                </div>               
            </div>

            <div  className="olympicforverify-button" onClick={confirmOtpcode}>
                <button className="olympicverifybutton-txt">VERIFY</button>
            </div>

            <div className="fanplay-verification">
                <CImage src={Fanplaylogo} className="verification-fanplaylogo"/>
            </div>

        </div>
    )
}
export default Olympicsverification;