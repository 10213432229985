import axios from "axios"
import { useContext } from "react";
import moment from "moment/moment";
// import { BASE_URL, CREATE_FAN_ENGAGEMENT, VALIDATE_SIGN_IN } from "../apiconstant";
import { CREATE_FAN_ENGAGEMENT , VALIDATE_SIGN_IN ,MY_FAN_EMOTESCORE,OTP_SIGNIN } from "../Api";
import { url , clientid, teamId } from "../constants";


let instance = axios.create({
  baseURL: url,
  headers: {
    Accept: 'text/plain',
    'Content-Type': 'application/json',
  },
});

function generateRandomNumber() {
  var minm = 10000;
  var maxm = 99999;
  return Math.floor(Math
    .random() * (maxm - minm + 1)) + minm;
}


export const validateSignIn = async (output,waName,loclat,loclong) => {
  // console.log(loclat , loclong)
  try {
    let data = await instance.post(VALIDATE_SIGN_IN,
      {
        roleid: 3,
        // phoneno:waNumber + "",
        displayname:waName + "",
        latitiude: loclat,
        longitude: loclong,
        clientid:clientid,
      },
      {
        headers: {
          Accept: 'text/plain',
          Authorization: `Bearer ${output.token}`,
          'Content-Type': 'application/json',
        },
      });
      // console.log(data);
      return data;
  } catch (e) {
    console.log(e);
    return -1;
  }
}


// export const validateSignIn = async (waNumber,waName,loclat,loclong) => {
//   // console.log(loclat , loclong)
//   try {
//     let data = await instance.post(VALIDATE_SIGN_IN,
//       {
//         roleid: 3,
//         phoneno:waNumber + "",
//         displayname:waName + "",
//         latitiude: loclat,
//         longitude: loclong,
//         clientid:clientid,
//       },
//       {
//         headers: {
//           Accept: 'text/plain',
//           'Content-Type': 'application/json',
//         },
//       });
//       // console.log(data);
//       return data;
//     // if (data.response.userSignUpId > 0) {
//     //   return data
//     // } else {
//     //   return -1;
//     // }
//   } catch (e) {
//     console.log(e);
//     return -1;
//   }
// }

export const otpSignIn = async(user,otpname,loclat,loclong) => {
  try{
    let outputtoken = await user.getIdTokenResult();
    const data = await axios.post(url +OTP_SIGNIN,{
        roleid: 1,
        displayname: otpname,
        latitiude: loclat,
        longitude: loclong,
        clientid: clientid,
    },{
        headers: {
            Accept: 'text/plain',
            Authorization: `Bearer ${outputtoken.token}`,
            'Content-Type': 'application/json',
          },
    });
    // console.log(data)
    return data;
}catch(e){
    console.log(e);
}
}


export const createFanEngage = async (usersid,sound, hr ,latloc,longloc) => {
  let tsStr = moment().utc(true).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  try {
    let data = await instance.post(CREATE_FAN_ENGAGEMENT,
      {
        sid: usersid,
        sound:sound,
        hrcount: Math.round(hr),
        hrdevicetype: 1,
        datacollectedts: tsStr,//"2023-02-08T16:53:56.071Z",
        teamcheered: teamId,
        tapcounts: 0,
        wavecounts: 0,
        whistlesredeemed: 0,
        whistlecounts: 0,
        fescore: 0,
        points: 0,
        latitude: latloc,
        longitude: longloc,
        devicemacid: "",
        hrzoneid: 1,
        affiliationid: 1,
        playertapcheer: [
        ],
        playerwavecheer: [
        ],
        playerwhistleredeemed: [
        ]
      }, {
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
    })
    // console.log(data.data);
    return data.data
  } catch (e) {
    console.log(e);
  }
}

// export const userCounts = async (option , usersid) => {
//   try{
//     let data = await axios.get(url + MY_FAN_EMOTESCORE + `?teamId=${option}&sid=${usersid}` , {
//         headers:{
//             Accept: 'text/plain',
//             // Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json',
//         },
//     });
//     return data.data.response.result;
// } catch (e) {
//   console.log(e)
// }
// }