import React from "react";
import './fanemote.css';
import { useState,useContext, useEffect , useRef } from "react";
import { FanEngageContext } from "../Auth/fanengage";
import { AuthContext } from "../Auth/Auth";
import moment from "moment";
import { SSEAPI } from "../Api";
// import Background from '../images/bgoutvs.png';
import Text from "./text";
// import CameraHR from "./camerahr";
// import Fanemote from "./Fanemote";
// import Myfanemote from "./Myfanemote";
// import Wars from "./wars";
// import Scorecard from "./scorecard";
// import Forlocation from "./getLocation";
// import Teamwar from "./teamwar";
// import Teams from "./Teams";
// import Swipe from "./swipe";
// import Footer from "./footer";
import Secondalign from "./secondalign";
import Userprofile from "./userprofile/userprofile";
// import Teamrank from "./teamrank";
import Leaderboard from "./leaderboard";
// import WaveSensor from "./wavesensor";
// import Like from '../images/like.png';
// import dislike from '../images/dislike.png';
// import { CImage } from "@coreui/react";

import Starsportslogo from '../images/starlogo.png';
import Fanplaylogo from '../images/fanplaybiggrey.png';
import IndiaLogo from '../images/teamindia.png'
import FanplayColorLogo from '../images/fanplaylogo.png'
import HabbaLogo from '../images/habbalogo.png'
import HabbaBgImg from '../images/habbabgimg.png'
import { CImage } from "@coreui/react";

import Lottie from "react-lottie";
import animationData from '../Lottie/Stream of Hearts.json'
import animationDatas from '../Lottie/cricket-four.json'
import animationDatase from '../Lottie/six-animation.json'
import animationDatases from '../Lottie/wicket.json'

// import ReactAudioPlayer from "react-audio-player";
import Fouraudio from '../audios/four.mp3';
import Outaudio from '../audios/out.mp3';
import Sixaudio from '../audios/six.mp3';
import { useNavigate } from "react-router-dom";

function Index(){

    const {otpUsername,currentUser} = useContext(AuthContext)
    const {heartrate , selectedteam , tmoneschedule,tmtwoschedule} = useContext(FanEngageContext);
    const [reconnect , setReconnect] = useState(true);
    const [loopforheart , setLoopforheart] = useState(false);
    const [events , setEvents] = useState('')
    let eventDate = useRef(null);
    let previousEventDate = useRef(null);
    var audioFour = new Audio(Fouraudio);
    var audioSix = new Audio(Sixaudio);
    var audioOut = new Audio(Outaudio);

    // const navigate = useNavigate();


    // if (!currentUser) {
    //     navigate('/');
    //   }
    

    // console.log(heartrate)

    const heartflow = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const fourlottie = {
        loop: true,
        autoplay: true,
        animationData: animationDatas,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const sixlottie = {
        loop: true,
        autoplay: true,
        animationData: animationDatase,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const wicketlottie = {
        loop: true,
        autoplay: true,
        animationData: animationDatases,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    // console.log(loopforheart);

    useEffect(() => {
        let settimeout = null;
        const evtSource = new EventSource(SSEAPI + `?teamid1=${tmoneschedule}&teamid2=${tmtwoschedule}`, {
            withCredentials: true,
        });
        evtSource.onerror = (event) => {
            if (evtSource.readyState == EventSource.CONNECTING) {
                console.log('SSE connecting');
            } else {
                // console.log('SSE error ');
                evtSource.close();
                setReconnect(!reconnect);
            }
        }
        evtSource.onmessage = (event) => {
            // console.log(event.data);
            let splitted = event.data.split(",")
            let compareDate = moment().subtract(30, 'minutes');
            let localdate = moment.utc(splitted[4],"MM/DD/YYYY hh:mm:ss a").local()
            // console.log(compareDate);
            if(eventDate.current == null && localdate.isAfter(compareDate)){
            setEvents(splitted[3])
            if(splitted[3] == 'Its a Six'){
                audioSix.play()
            }
            if(splitted[3] == 'Its a Four'){
                audioFour.play()
            }
            if(splitted[3] == 'Its a Wicket'){
                audioOut.play()
            }

            settimeout = setTimeout(() => {
                setEvents('');
              },3800)
            }

            if(eventDate.current != null && eventDate.current != splitted[4] && localdate.isAfter(compareDate)){
                setEvents(splitted[3])
                if(splitted[3] == 'Its a Six'){
                    audioSix.play()
                }
                if(splitted[3] == 'Its a Four'){
                    audioFour.play()
                }
                if(splitted[3] == 'Its a Wicket'){
                    audioOut.play()
                }

                settimeout = setTimeout(() => {
                    setEvents('');
                  },3800)
            }
            eventDate.current = splitted[4]
        }
       return () => {
        evtSource.close();
    }}, [reconnect]);

   
    return(
        <div className="entire-body">
            

            <div className="toplogos-index">
                <CImage src={FanplayColorLogo} className="starlogo-index"/>
                {/* <CImage src={Ipllogo} className="ipllogo-whatsapp" /> */}
            </div>

            {/* <div>
                <CImage src={HabbaBgImg} className="habbabg-img" />
            </div> */}

            {/* <div className="index-thirdline">
                <div className="thirdline-fanwarchart">
                    <Scorecard/>
                </div>
            </div> */}

            {/* <div className="index-firstline">
                <Text/>
            </div> */}

            <div className="index-userprofile">
                <Userprofile/>
            </div>

            <div className="index-secondline">
                {/* <p className="cheering-txt">I am cheering for</p> */}
                <div className="secondline-Teams">
                <p className="cheering-txt">I am cheering for</p>
                    {/* <Swipe/> */}
                    <div className="forindialogo-div">
                        <CImage src={IndiaLogo} className="onlyindialogo" />
                    </div>
                </div>
                
                <div className="secondline-hr">
                    <Secondalign setLoopforheart={setLoopforheart}/>
                </div>
                
                <div className="secondline-fanwars">
                    {/* <Teamwar/> */}
                    {/* <Teamrank/> */}
                    <Leaderboard />
                </div>
            </div>

            
            <div className="index-fourthline">
                {/* <div className="fourthline-footer">
                    <Footer />
                </div> */}
                <div className="fanplay-index">
                    <CImage src={Fanplaylogo} className="index-fanplaylogo"/>
                </div>
                <div className="fanplay-indextxt">
                    <p className="fanplayindex-txt">© FanPlayIoT Technologies 2024</p>
                </div>
            </div>

            


             {loopforheart == true ? 
             <div className="heart-lottie">
                <Lottie 
                options={heartflow}
                // height={710}
                // width={400}
                size={30}
                className="heart-lottie"/>
            </div>
             :
            null
            }  
        </div>
    )
}
export default Index;