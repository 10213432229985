import React, { useEffect, useRef , useContext , useState } from "react";
import useLocalStorage from '../Hook/useLocalStorage';
// import { createFanEngage } from "../Service/fanengageapi";
import { createFanEngage , userCounts } from "../Service/fanengageapi";
import { url ,sportid ,tournamentid} from "../constants";
import { GET_SCHEDULE , GET_ALL_TEAMS , UPDATE_USERNAME_TEAMS } from "../Api";
import axios from "axios";
import moment from "moment";


export const FanEngageContext = React.createContext();

export const FanEngageProvider = ({ children }) => {
    const geolocationAPI = navigator.geolocation;

    const [checked, setChecked] = useState(false);
    const [sortingdata , setSortingdata] = useState()

  const [scheduleteam , setScheduleteam] = useState([]);
  const [heartrate, setHeartrate] = useLocalStorage("hr", 0);
  const [tapcount, setTapcount] = useLocalStorage("tap", 0);
  const [selectedteam, setSelectedteam] = useLocalStorage("team", 0);
  // const [selectedteamname, setSelectedteamname] = useLocalStorage("teamname", 0);

  // const [totalTapcount , setTotalTapcount] = useLocalStorage("tapcounts" , 0);
  const [latloc, setlatloc] = useLocalStorage("lat", 0);
  const [longloc, setlongloc] = useLocalStorage("long", 0);

  const [whistles, setWhistles] = useLocalStorage("whistle", 0);
  // const [Waves, setWaves] = useLocalStorage("waves", 0);

  // const [sound , setSound] = useLocalStorage("sound" ,0);
  const [tmoneschedule , setTmoneschedule] = useLocalStorage("tmoneschedule",0)
  const [tmtwoschedule , setTmtwoschedule] = useLocalStorage("tmtwoschedule",0)
  const [tmthreeschedule , setTmthreeschedule] = useLocalStorage("tmthreeschedule",0)
  const [tmfourschedule , setTmfourschedule] = useLocalStorage("tmfourschedule",0)
  // const [urloneschedule , setUrloneschedule] = useLocalStorage("urloneschedule",'')
  // const [urltwoschedule , setUrltwoschedule] = useLocalStorage("urltwoschedule",'')


  let userSound = useRef(0);
  let reflatloc = useRef(0);
  let reflongloc = useRef(0);
  let hrArray = useRef([]);
  let tapCountRef = useRef(0);
  // let waveCountRef = useRef(0);
  let hrcompare = useRef([])


  const options = [
    {teamid: 66 , teamname: 'India',teamlogourl: 'https://fanguruprodstrg.blob.core.windows.net/teamslogoandbg/Teamslogo_66_India.png'},
    {teamid: 69 , teamname: 'New Zealand',teamlogourl: 'https://fanguruprodstrg.blob.core.windows.net/teamslogoandbg/Teamslogo_69_newland.png'},
    {teamid: 78 , teamname: '"myfitstudio',teamlogourl: 'https://fanguruprodstrg.blob.core.windows.net/teamslogoandbg/Teamslogo_79_bangalore.png'},
    {teamid: 7 , teamname: 'CSK',teamlogourl: 'https://fanguruprodstrg.blob.core.windows.net/teamslogoandbg/Teamslogo_7_ipl_CSK_W.png'}
];

// const options = [
//   {teamid: 0 , teamname: 'India',teamlogourl: 'https://fangurudevstrg.blob.core.windows.net/teamslogoandbg/Teamslogo_86_flag_india.png'},
//   {teamid: 0 , teamname: 'Australia',teamlogourl: 'https://fangurudevstrg.blob.core.windows.net/teamslogoandbg/Teamslogo_107_Australia.png'}
// ];

  const updateHeartRate = (hr) => {
    hrArray.current?.push(hr);
  }


  const setLocation = (lat ,long) => {
    // console.log(lat, long);
    if(lat !== null && long !== null && lat !== undefined  && long !== undefined ){
    setlatloc(lat);
    setlongloc(long);
    reflatloc.current = lat;
    reflongloc.current = long;
    }
    else{
      setlatloc(reflatloc.current);
      setlongloc(reflongloc.current);
    }
  }

  const incrementTap = () => {
    tapCountRef.current = tapCountRef.current ? (tapCountRef.current + 1) : 1;
    setTapcount(tapCountRef.current);
  }

  // const incrementWave = () => {
  //   waveCountRef.current = waveCountRef.current ? (waveCountRef.current + 1) : 1;
  //   setWaves(waveCountRef.current);
  // }

  const onHRInterval = async (usersid) => {
    // let loc = geolocationAPI.getcurrent;
    // console.log(loc)
    let arr = hrArray.current ? hrArray.current : [];
    let avghr = arr.reduce((prev, curr, index, arr) => {
      return prev + curr / arr.length;
    }, 0);
    //console.log('Average HR', avghr, arr.length);
    setHeartrate(avghr);
    if (avghr > 40 && avghr < 180) {
      // let tapTotal = tapCountRef.current ? tapCountRef.current : 0;
      let roundedhr = Math.round(avghr)
      if(hrcompare.current.length > 4){
        const lastvalues = hrcompare.current.slice(-4);
        hrcompare.current.push(roundedhr)
        const newValues = lastvalues.indexOf(roundedhr);
        if (newValues === 0){
          console.log("equall")
        }
        else{
          // console.log("not equal")
          await createFanEngage(usersid,userSound.current, avghr,latloc ,longloc,tapcount).then((result) => {
            hrArray.current = [];
          });
        }
      }
      // await createFanEngage(usersid,userSound.current, selectedteam, avghr,latloc ,longloc).then((result) => {
      //   hrArray.current = [];
      // });
      else{
        hrcompare.current.push(roundedhr)
        // console.log(hrcompare.current)
        await createFanEngage(usersid,userSound.current, avghr,latloc ,longloc,tapcount).then((result) => {
          hrArray.current = [];
        });
      }
    } else {
      hrArray.current = [];
    }
  }


  const onTapset = async (teamstapcounts) => {
    // console.log(teamstapcounts)
    tapCountRef.current = teamstapcounts;
    setTapcount(tapCountRef.current);
  }


  const onWhistleset = async (teamswhistlecounts) => {
    // console.log(teamswhistlecounts)
    setWhistles(teamswhistlecounts);
  }

  // const onWaveselect = async (teamswaves) => {
  //   // console.log(teamswaves)
  //   waveCountRef.current = teamswaves
  // }


  const onTeamSelect = async (schedulematch ,usersid,userwaName,usernumber) => {
    // console.log( schedulematch ,userwaName);
    setSelectedteam(schedulematch)
    // if(schedulematch > 0){
    //   let data = await axios.post(url + UPDATE_USERNAME_TEAMS + `?teamid=${schedulematch}&sid=${usersid}&displayname=${userwaName}&mobilenumber=${usernumber}`);
    //       if(data.statuscode == 200){
    //         return data;
    //       }
    // }
    // setSelectedteamname(schedulematch.teamname)
    // userCounts(options.value)
    // let response = await userCounts(schedulematch.teamid , usersid);
    // // console.log(response[0]);
    // if(response && response.length > 0){
    // await onTapset(response[0].tapcounts);
    // await onWhistleset(response[0].tapcounts);
    // // await onWaveselect(response[0].wavecounts)
    // }
  }


  const onTeamInit = async (usersid) => { 
    let team_id = selectedteam;
    // if(selectedteam == 0){
    //   console.log(tmoneschedule)
    //   team_id = tmoneschedule;
    // }
    // let response = await userCounts(team_id, usersid);
    // if (response && response.length > 0) {
    //    await onTapset(response[0].tapcounts);
    //    await onWhistleset(response[0].tapcounts);
    //   //  await onWaveselect(response[0].wavecounts)
    //   }
    }

    const onUserSound = async (decibel) => {
      // console.log(decibel);
      userSound.current = decibel;
    }

    // const onSchedule = async (schedules) => {
    //   // console.log(schedules[1]);
    //   setTmoneschedule(schedules[0].teamid);
    //   setTmtwoschedule(schedules[1].teamid);
    //   setUrloneschedule(schedules[0].teamlogourl);
    //   setUrltwoschedule(schedules[1].teamlogourl)
    // }


    useEffect(() => {
      const getAllteams = async () => {
        // let todaydate = moment().format("MMMM DD, dddd");
        try{
          // const data = await axios.get(url + GET_SCHEDULE + `?year=2023&dateandday=${todaydate}`)
          const data = await axios.get(url + GET_ALL_TEAMS + `?sportid=${sportid}&tournamentid=${tournamentid}`);
          if(data.data.response && data.data.response.result && data.data.response.result.length > 0 ){
            // console.log(data.data.response.result);
            let schedules = data.data.response.result
            setScheduleteam(schedules);
            // setFirstteam(schedules)
            // onSchedule(schedules);
          }
          else{
            setScheduleteam(options)
            // onSchedule(options);
          }
        }catch (e){
          console.log(e)
        }
      }

      const getTeamsschedule = async () => {
        let todaydate = moment().format("MMMM D, dddd");
        // console.log(todaydate);
        try{
          const data = await axios.get(url + GET_SCHEDULE + `?year=2023&dateandday=${todaydate}`)
          if(data.data.response && data.data.response.sub && data.data.response.sub.length > 0 ){
            // console.log(data.data.response.sub);
            if(data.data.response.sub.length === 1){
            let scheduled = data.data.response.sub
            setTmoneschedule(scheduled[0].teams[0].teamid);
            setTmtwoschedule(scheduled[0].teams[1].teamid);
            setTmthreeschedule(options[2].teamid);
            setTmfourschedule(options[3].teamid)
            }
            if(data.data.response.sub.length === 2){
              let scheduled = data.data.response.sub
              setTmoneschedule(scheduled[0].teams[0].teamid);
              setTmtwoschedule(scheduled[0].teams[1].teamid);
              setTmthreeschedule(scheduled[1].teams[0].teamid);
              setTmfourschedule(scheduled[1].teams[1].teamid)
            }
            // setFirstteam(schedules)
            // onSchedule(schedules);
          }
          else{
            setTmoneschedule(options[0].teamid);
            setTmtwoschedule(options[1].teamid);
            setTmthreeschedule(options[2].teamid);
            setTmfourschedule(options[3].teamid)
          }
          
        }catch (e){
          console.log(e)
        }
      }
      getAllteams();
      getTeamsschedule();
    },[])
  
 
  return (
    <FanEngageContext.Provider value={{ updateHeartRate, incrementTap, tapcount, onHRInterval ,heartrate , onTeamSelect , selectedteam  ,onTapset , setLocation , onWhistleset , onTeamInit, whistles , onUserSound ,scheduleteam , tmoneschedule,tmtwoschedule,tmthreeschedule,tmfourschedule,checked, setChecked,sortingdata , setSortingdata}}>
      {children}
    </FanEngageContext.Provider>
  );
}