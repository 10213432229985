import React from "react";
import './fanemote.css'
import { useState , useEffect , useContext } from "react";
import { FanEngageContext } from "../Auth/fanengage";
import { AuthContext } from "../Auth/Auth";


function Forlocation (){

    const { onLocation } = useContext(AuthContext);
    const {setLocation} = useContext(FanEngageContext)

    const [lat, setLat] = useState(0);
    const [long, setLong] = useState(0);
    const [error , setError] = useState('');

    const geolocationAPI = navigator.geolocation;

    const getUserCoordinates = () => {
        if (!geolocationAPI) {
          setError('Geolocation API is not available in your browser!')
        } else {
          geolocationAPI.getCurrentPosition((position) => {
            const { coords } = position;
            setLat(coords.latitude);
            setLong(coords.longitude);
            onLocation(coords.latitude , coords.longitude);
            setLocation(coords.latitude , coords.longitude)
          }, (error) => {
            setError('Something went wrong getting your position!')
          })
        }}

        useEffect(() => {
            getUserCoordinates();
        },[])


}
export default Forlocation;