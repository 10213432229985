import React from "react";
import './otp.css'
import { useState,useEffect,useContext,useRef } from "react";

import { useNavigate } from "react-router-dom";
import { url } from "../../constants";
import { OTP_SIGNIN } from "../../Api";
import axios from "axios";

// import Starsportslogo from '../../images/starlogo.png';
// import HabbaLogo from '../../images/habbalogo.png'
// import HabbaBgImg from '../../images/habbabgimg.png'
import Fanplaylogo from '../../images/fanplaybiggrey.png';
import Olympicslogo from '../../images/olympicslogo.png'
import { CImage } from "@coreui/react";
import { AuthContext } from "../../Auth/Auth";
import firebaseConfig from "../../config";
import 'firebase/compat/auth'
// import 'firebase/compat/analytics'
// import firebase from "firebase/compat/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth";



function Otp(){

    const {otpUsername,currentUser} = useContext(AuthContext)
    const [userName , setUserName] = useState('');
    const [mobileNo , setMobileNo] = useState('');
    const [push, setPush] = useState(false);


    const navigate = useNavigate();

    if(!window.recaptchaVerifier){
        window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                onSignInSubmit();
            }
        }, getAuth(firebaseConfig));
        }
        window.recaptchaVerifier.render();


    const onSignInSubmit = () => {
        console.log("onSignInSubmit");
    }


    const signInOtp = () => {
        try{
            if(userName.length > 0 && mobileNo.length > 0){
            otpUsername(userName,mobileNo)
            let phoneNumber = '+91' + mobileNo;
            // console.log(phoneNumber , userName)
            const appVerifier = window.recaptchaVerifier;
            const auth = getAuth();
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // console.log(confirmationResult);
                window.confirmationResult = confirmationResult;
                navigate("/verification")
            })
            .catch((error) => {
                alert(error.message);
            })
            }
            if(userName === ''){
                alert("please enter your name")
            }
            if(mobileNo === ''){
                alert("please enter your mobile number")
            }
        }catch (e){
            console.log(e)
        }
    }

    // const tologout = () => {
    //     const auth = getAuth();
    //     signOut(auth);
    //     navigate('/')
    // }

    return(
        <div className="otpscreen">

            <div className="toplogos-otp">
                {/* <CImage src={Olympicslogo} className="starlogo-otp"/> */}
            </div>

            <div className="otplogin-txthdng">
                <p className="loginwith-otptxt">LOG IN WITH OTP</p>
            </div>

            <div className="nameinputsfor-otp">
                <input 
                placeholder="Display Name"
                value={userName}
                required
                onChange={(e) => setUserName(e.target.value)}
                className="nameinputbox"/>
            </div>

            <div className="numberinputsfor-otp">
                <p className="numberfirst">+91</p>
                <input 
                placeholder="999-999-9999"
                value={mobileNo}
                required
                onChange={(e) => setMobileNo(e.target.value)}
                className="numberinputbox"/>
            </div>

            <div id="forverify-buttonid" className="forverify-button" onClick={signInOtp}>
                <button className="verifybutton-txt">VERIFY</button>
            </div>

            <div className="fanplay-otp">
                <CImage src={Fanplaylogo} className="otp-fanplaylogo"/>
            </div>

        </div>
    )
}
export default Otp;