import React from "react";
import { useState, useEffect } from "react";
import './fanemote.css'

import { url, teamId } from "../constants";
import { HR_LEADERBOARD, USER_HR_PERDAY } from "../Api";
import axios from "axios";
import moment from "moment";
import { CImage } from "@coreui/react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css"

SwiperCore.use([Autoplay]);



function Leaderboard (){

    const [leaderBoards, setLeaderBoards] = useState([]);
    const [hrCounts, setHrCounts] = useState(0);


    const forLeaderBoard = async () => {
        const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
        const prevdayFormat = moment().format("YYYY-MM-DD") + 'T00:00:00';
        try {
            const { data } = await axios.get(`${url}${HR_LEADERBOARD}?teamId=${teamId}&count=10&from=${prevdayFormat}&to=${currentDate}`);
            if (data.response && data.response.leaderboard && data.response.leaderboard.length > 0) {
                const getLeaderBoard = data.response.leaderboard[0].leaderboard;
                setLeaderBoards(getLeaderBoard);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        forLeaderBoard();
        const intervalId = setInterval(forLeaderBoard, 30000);
        return () => clearInterval(intervalId);
    }, []);


    function abbreviateNumber(value) {
        var newValue = value;
        if (value >= 1000) {
            var suffixes = ["", "K", "M", "B","T"];
            var suffixNum = Math.floor( (""+value).length/3 );
            var shortValue = '';
            for (var precision = 2; precision >= 1; precision--) {
                shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
                if (dotLessShortValue.length <= 2) { break; }
            }
            if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
            newValue = shortValue+suffixes[suffixNum];
        }
        return newValue;
    }


    return(
        <div className="foruserleaderboard">
            {leaderBoards.length > 0 ?
            <div className="entire-usersleaderboard">
                <div className="forleaderboard-heading">
                    <p className="forleaderboard-headingtxt">LEADERBOARD</p>
                </div> 
                <Swiper
                slidesPerView={5}
                spaceBetween={0}
                direction="vertical"
                mousewheel={true}
                autoplay={{ delay: 10000 }}
                className="mySwiper"
                >
                {/* {leaderBoards.sort((a,b) => b.totalheartratecount - a.totalheartratecount).map((item, index) => ( */}
                {leaderBoards.map((item, index) => (
                    <SwiperSlide key={item.sid}>
                    <div className="userleaderboard-eachusers">
                        <div className="usersranklist-item-leaderboard">
                            <CImage src={item.imgpath} className="userimages-teamrank" />
                            <p className="userrank-leaderboard">{index + 1}</p>
                            {/* <p className="userrank-leaderboard">{item.rank}</p> */}
                            <p className="userrank-usernameleaderboard">{item.name}</p>
                            <p className="userrank-totalcheerleaderboard">{item.totalheartratecount}</p>
                            {/* <p className="userrank-totalcheerleaderboard">{item.points}</p> */}
                        </div>
                    </div>
                    </SwiperSlide>
                ))}
                </Swiper>
                
            </div>
            :
            null
            }
        </div>
    )
}
export default Leaderboard;