import React from "react";
import './userprofile.css'
import { useState,useEffect,useRef,useContext } from "react";
import { url, teamId } from "../../constants";
import { GET_ALL_TEAMS , UPDATE_USERNAME_TEAMS , USER_NAME} from "../../Api";
import { AuthContext } from "../../Auth/Auth";
import { FanEngageContext } from "../../Auth/fanengage";
import { useNavigate } from "react-router-dom";

// import { WhatsappIcon, WhatsappShareButton} from "react-share";
// import { Close } from "@mui/icons-material";
import { CImage, CModal ,CModalBody,CModalHeader,CModalTitle,CModalFooter } from "@coreui/react";

// import Starsportslogo from '../../images/starlogo.png';
// import Ipllogo from '../../images/ipllogo.png';
// import Sharebtn from '../../images/shareicon.png';
import Editicon from '../../images/editbtnicon.png';
// import Fanplaylogo from '../../images/fanplaybig.png';
import Close from '../../images/close.png';
import Closey from '../../images/closey.png'
import Tick from '../../images/Tick.png'
import axios from "axios";

function Userprofile() {

    const {usersid,apiname,usernumber,userSelectTeam , Userdisplayname,userprofileotpMobileNo} = useContext(AuthContext);
    const {selectedteam} = useContext(FanEngageContext)
    // console.log(usersid)
    // const [displayname , setDisplayname] = useState();
    const [userName , setUserName] = useState(apiname)
    const [updateuserName , setUpdateUserName] = useState('')
    const [apiusername , setApiusername] = useState('')
    const [userTeam , setUserTeam] = useState(0);
    const [visible, setVisible] = useState(false)

    // const [allTeams , setAllteams] = useState([]);
    // const [editable , setEditable] = useState(false)
    // const [visible, setVisible] = useState(false);
    // const [open, setOpen] = useState(false);
    // const navigate = useNavigate();

    // const shareUrl = "https://fanemotedevlitereact.azurewebsites.net" 


    // const Teamselect = (teamid) => {
    //     // console.log(teamid);
    //     setUserTeam(teamid)
    //     userSelectTeam(teamid)
    // }

   
    const getUsername = async () => {
        setVisible(false)
        try{
          let data = await axios.get(url + USER_NAME + `?userId=${usersid}`)
          if(data.data.response && data.data.response.result && data.data.response.result.length > 0 ){
            // console.log(data.data.response.result[0].displayname);
            let profile = data.data.response.result[0];
            let usernames = profile.displayname;
            setApiusername(usernames)
          }
        }catch(e){
          console.log(e);
        }
      }


      const getupdateUser = async () => {
        try{
            // console.log(selectedteam,userprofileotpMobileNo,updateuserName)
                let data = await axios.post(url + UPDATE_USERNAME_TEAMS + `?teamid=${teamId}&sid=${usersid}&displayname=${updateuserName}&mobilenumber=${userprofileotpMobileNo}`);
                // console.log(data.data.statuscode)
                if(data.data.statuscode === 200){
                    setVisible(false)
                    // getUsername();
                    // return data;
                    const timeout = setTimeout(() => {
                        getUsername();
            
                      }, 1100)
                  
                      return () => clearTimeout(timeout)
                    return data;
            
                }
              
        }catch(e){
          console.log(e);
        }
      }

      useEffect(() => {
        getUsername();
        // let intervaldata = null;
        // getUsername();
        // intervaldata = setInterval(() => {
        //     getUsername();
        // }, 1000);
        // return () => {
        //     if(intervaldata)
        // clearInterval(intervaldata);
        // }
        const timeout = setTimeout(() => {
            getUsername();

          }, 1200)
      
          return () => clearTimeout(timeout)
      },[])

    useEffect(() => {
        // console.log(userName , displayname)
        if( userName != null){
            Userdisplayname(userName);
        }
    },[userName])

    return(
        <div className="foruser-profile">

            
            {/* <div className="forshare-userprofile">
                <CImage src={Sharebtn} className="sharelogo-userprofile" onClick={()=> setVisible(!visible)}/>
                <CModal
                visible={visible} onClose={() => setVisible(false)}
                className="share-link">
                    <CModalHeader onClose={() => setOpen(false)} className="sharemodal-head">
                        <CModalTitle className="modal-sharetxt">Share</CModalTitle>
                        <h6 className="modal-shareclose" onClick={() => setVisible(false)}><Close /></h6>
                    </CModalHeader>

                    <CModalBody className="socialmedia-model">
                        <WhatsappShareButton url={shareUrl}>
                            <WhatsappIcon size={35} round={true} className="whatsapp-logo"/>
                        </WhatsappShareButton>
                    </CModalBody>

                    <CModalFooter className="socialmedia-names">
                        <CModalTitle className="whatsapp-txt">What's App</CModalTitle>
                    </CModalFooter>
                </CModal>
            </div> */}

            {/* <div className="name-userprofile">
                <p className="userprofile-namechangetxt">Your name / Nick name</p>
            </div> */}

            <div className="inputfor-userprofile">
                <p style={{visibility: visible == true ? 'hidden' : 'visible'}} className="usernametxt-userprofile">{apiusername}</p>
                {visible == false ? 
                <CImage src={Editicon} className="editbtn-iconuser" onClick={() => setVisible(!visible)}/>
                :
                null
                }
                <CModal className="search-modal" visible={visible} >
                   <CModalBody className="search-body">
                    <div className="input-userprofile">
                        <input 
                        className="inputbox-username"
                        placeholder={apiusername}
                        contentEditable
                        onChange={(e) => setUpdateUserName(e.target.value)}/>
                        {teamId > 0 ?
                        (
                            <>
                        {updateuserName.length > 0 ?
                        <CImage src={Tick} className="closeimage-inmodel" onClick={getupdateUser} />
                        :
                        <CImage src={Tick} className="closeimage-inmodel" onClick={getUsername}/>
                        }
                           </>
                        )
                        :
                        null}
                    </div>
                    </CModalBody>
                </CModal>
            </div>

            {/* <div className="inputfor-userprofile">
                <input 
                className="inputbox-username"
                placeholder={userName}
                // contentEditable={false}
                onChange={(e) => setUserName(e.target.value)}/>
                <CImage src={Editicon} className="editbtn-iconuser"/>
            </div> */}


        </div>
    )
}
export default Userprofile;