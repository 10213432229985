import React, { useState, useRef } from "react";


function useCameraHR(type) {

var ctx
var canvas
var video
var frameSumArr = []

// Canvas stuff
var myChart

// FFT stuff
var fftr1
var arrLen = 256
var maxInd, maxVal

// normalization
var cropSize = 100;
var normFactor = 1/(cropSize*cropSize*25);
var filter = Array.prototype.filter.call.bind(Array.prototype.filter)
var t0 = performance.now();

// Getting frequency
var times = [];
var timesLen = arrLen/4;
var curPollFreq;

// Heartrate
var heartrate


function drawToCanvas(element_id, data) {
  const element = document.getElementById(element_id);
  const width = element.clientWidth;
  const height = element.clientHeight;
  const n = data.length;

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  element.appendChild(canvas);

  const context = canvas.getContext('2d');
  context.strokeStyle = 'blue';
  context.beginPath();
  data.forEach((c_value, i) => {
    context.lineTo(i * width / n, height/2 * (1.5 - c_value.real));
  });
  context.stroke();
}


function onCapabilitiesReady(capabilities) {
  // console.log(capabilities);
}

const constraints = {
    Audio: true,
    facingMode: type,
    video: {width: 300 , frameRate: { ideal: 10, max: 15 }}
};

async function setupCamera() {
    // navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    // if(navigator.getUserMedia){}
    await navigator.mediaDevices.getUserMedia
    ({
      video: {
        // facingMode: 'environment',
        facingMode: type, //'user',
        width: 300,
      }
    })
    .then((stream) => {
      
      video = document.querySelector('video');
      if (video != null){
        video.setAttribute('autoplay','');
        video.setAttribute('muted' , '');
        video.setAttribute('playsinline', '');
      video.srcObject = stream;

        // get an active track of the stream to print capabilities
        const track = stream.getVideoTracks()[0];
        video.addEventListener('loadedmetadata', (e) => {
          window.setTimeout(() => (
            onCapabilitiesReady(track.getCapabilities())
          ), 500);
        });

        // Added trigger to get video width and height
      video.addEventListener( "loadedmetadata", loadCanvas, false );
      }
    })
    .catch(err => console.error('getUserMedia() failed: ', err));
    let heartrateIndicator = document.getElementById('heartrate');
    heartrateIndicator.textContent = "Allow camera access";
    console.log('setuep cmaera done');
}

async function loadCanvas(){
    // Set up canvas
    canvas = document.getElementById('output');
    canvas.width = video.videoWidth *.8
    canvas.height = video.videoHeight *.60
    ctx = canvas.getContext('2d');
    video.play();
    // animate()
    drawWebcam();
    pollctx();
}

function getCordinatesOnHeartShape(x, y) {
    var cordinates = [];
    var pi = Math.PI;
    for (var t = 0; t <= 360; t++) {
      var tr = (t * pi) / 180;
      cordinates[t] = {
        x: (x - (16 * Math.sin(tr) * Math.sin(tr) * Math.sin(tr))),
        y: (y - ((13 * Math.cos(tr)) - (5 * Math.cos(2 * tr)) - (2 * Math.cos(3 * tr)) - Math.cos(4 * tr)))
      };
    }
  
    return cordinates;
  }

  let size = 1;
  let a = 0;
  
  let x = 100;
  let y = 100;


  function animate() {
    a += 0.05;
    ctx.fillStyle = 'white';
    ctx.fillRect(-canvas.width / 2, -canvas.height / 2, canvas.width, canvas.height);
  
    size = 2 - Math.cos(a);
    ctx.resetTransform();
    ctx.translate(x, y);
  
    ctx.save();
    ctx.beginPath();
    ctx.fillStyle = 'red';
    var hc = getCordinatesOnHeartShape(x, y);
    ctx.translate(-size * x, -size * y);
    ctx.scale(size, size);
    for (var i = 0; i < hc.length; i++) {
      ctx.lineTo(hc[i].x, hc[i].y);
    }
    ctx.fill();
    ctx.restore();
  
  
    requestAnimationFrame(animate);
  }
  
  

async function drawWebcam(){
    ctx.drawImage(video,0,0,canvas.width, canvas.height);
//    console.log('webcam drawn');

    requestAnimationFrame(drawWebcam);
}


// Use with below code to get only red, maybe improve SNR
//    videoDataSum = filter(videoData, everyFourth)
//        .reduce((a, b) => a + b, 0);
function everyFourth(elem, i) {
    return (i-2) % 4 == 0

}

async function pollctx(){
    // Get Image data from canvas
    let videoData = ctx.getImageData(100, 100, cropSize, cropSize).data;

    // Sum pixels of video data
    let videoDataSum = videoData.reduce((a, b) => a + b, 0);
    videoDataSum -= cropSize*cropSize*255 // remove alpha
    videoDataSum = videoDataSum*normFactor;

        // Add sum to array
    frameSumArr.push(videoDataSum);
    if (frameSumArr.length > arrLen){
        frameSumArr.shift()

        // Calculate the FFT and update the chart
        calcFFT();
    }else{
        // console.log(frameSumArr.length)
    }


    // Get next animation

    times.push(performance.now()-t0);
    if (times.length > timesLen){
        times.shift();
        curPollFreq = 1/(times.reduce((a, b) => a + b, 0)/timesLen/1000);

        if (isNaN(heartrate)){
            heartrate = maxInd*curPollFreq/arrLen*30;
        }

        heartrate = heartrate + ( maxInd*curPollFreq/arrLen*30 - heartrate)*.01;
        let controlhr = heartrate >= 160 ? 160 : heartrate <= 50 ? 50 : heartrate
        let heartrateIndicator = document.getElementById('heartrate')
        heartrateIndicator.textContent = "" + Math.round(controlhr) 

    }
    t0 = performance.now();
    requestAnimationFrame(pollctx);
    // myChart.update(); // This position prevents the graph from jumping
}

async function calcFFT(){

    let tmp = fftr1.forward(frameSumArr);

    maxVal = 0;
    maxInd = 0;

    // Find peaks past the meyer wave and DC noise freqs
    tmp.forEach((item, index, arr) => {
        arr[index] = Math.abs(item)
        if (index > 8 && arr[index] > maxVal){
            maxInd = index
            maxVal = arr[index]
        }
    })
    // updateChart(myChart, tmp.slice(1))

}



function updateChart(chart, datas){
    chart.data.datasets = [{
            label: 'Heartrate based on FFT',
            data: datas,

            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }];

}

//function sumImage()

async function main() {
    // Set up camera
    await setupCamera();



    fftr1 = new window.kiss.FFTR(arrLen);





/* 
    var ctx = document.getElementById('myChart').getContext('2d');
    myChart = new Chart(ctx, {
    type: 'line',
    data: {
        labels: [...Array(arrLen).keys()],
        datasets: [{
            label: 'FFT Maybe',

            data: [12, 19, 3, 5, 2, 3],

            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }]
    },
    options: {
//        responsive:true,
//        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
//                    max: .2,
                    max: 1,
                    min: -1
                }
            }]
        }
    }
    }); */
}

//main();
return main;
}
export default useCameraHR;