import React from "react";
import './fanemote.css'
import CameraHR from "./camerahr";
import Audiofile from "./audiofile";
// import Tapwave from "./tapwave";

function Secondalign ({setLoopforheart}) {
    

    return(
        <div className="secondlines-aligns">
            <div className="second-firstalign">
                <div className="forcamera-hr">
                    <CameraHR setLoopforheart={setLoopforheart}/>
                </div>
                <div className="for-audiofiles">
                    <Audiofile/>
                </div>
            </div>
            {/* <div className="second-secondalign">
                <Tapwave/>
            </div> */}
        </div>
    )
}
export default Secondalign;