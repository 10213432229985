// export const FANEMOTE_API = "/api/Dashboard/GetAverageFanEmote"
// export const LEADERBOARD = "/api/LeaderBoard/GetMobileLeaderboardWithUser"
// export const GET_QUIZ_QUESTIONS = "/api/Contest/GetPollsOrQuiz"
// export const SUBMIT_QUIZ_ANSWER = "/api/Contest/UserPollsOrQuiz"
// export const MY_FAN_EMOTESCORE = "/api/Dashboard/GetFEDetailsByTeamId"

// export const MY_FAN_EMOTESCORE = "/api/FELite/GetFEDetailsByTeamIdWithSid"
// export const TEAM_TAP_COUNT = "/api/FELite/GetTotalTapCountByTeamId"

// export const TEAMS_WAR = "/api/Dashboard/TotalHRCountByTeamId"
// export const TEAMS_WAR = "/api/FELite/TotalHRCountAndSoundByTeamId"
export const TEAMS_WAR = "/api/FELite/GetTolatHrCountByTournamentId"



export const SCORE_CARD = "/api/CricketApi/GetCricketScoreCard"

// export const HEART_WAR = "/api/FELite/GetAverageHR"
// export const ONLINE_USER = "/api/FELite/GetTotalOnlineUsers"
export const ONLINE_USER = "/api/FELite/GetTotalOnlineUsersWithDate"



// export const CREATE_FAN_ENGAGEMENT = "/api/FanEngagement/CreateFanEngagement";
export const CREATE_FAN_ENGAGEMENT = "/api/FELite/CreateFanEngagementWithSid";
// export const VALIDATE_SIGN_IN = "/api/Login/ValidateSignIn";
// export const VALIDATE_SIGN_IN = "/api/FELite/ValidateWhatsAppLoginV1";
export const VALIDATE_SIGN_IN = "/api/FELite/ValidateSignInClient";



// export const GET_SCHEDULE = "/api/FELite/GetFEDetailsByMatchSchedulesForFELite"
export const GET_SCHEDULE = "/api/FELite/GetFEDetailsByTwoMatchSchedulesForFELite"


export const GET_ALL_TEAMS = "/api/Master/GetAllTeamsV1"
export const UPDATE_USERNAME_TEAMS = "/api/FELite/UpdateUserNameNumber"

export const USER_NAME = "/api/User/GetCompleteUserDetails"

//dev
// export const SSEAPI = "https://pdf20220722131915.azurewebsites.net/api/CricketMatchUpdate"

//prod
export const SSEAPI = "https://ball-by-ball-prod.azurewebsites.net/api/CricketMatchUpdate"

export const OTP_SIGNIN = "/api/FELite/ValidateSignInClient"

export const WHATSAPP_TOKEN = "https://ball-by-ball-prod.azurewebsites.net/api/getCustomToken"

export const HR_LEADERBOARD = "/api/LeaderBoard/GetLeaderboard"
export const USER_HR_PERDAY = "/api/User/GetHRforUser"