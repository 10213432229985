import React from "react";
import { useState } from "react";
import './fanemote.css'
import useAudioLevel from "../Hook/useAudioLevel"
// import Lottie from "react-lottie";
// import animationData from '../Lottie/sound-visualisation.json';
// import animationDatas from '../Lottie/green-sound.json';
import Micimg from '../images/mic.png';
import Mutedmic from '../images/mic_mute.png';
import { CImage } from "@coreui/react";

function Audiofile() {

    const [onoffmicrophone , setOnOffMicrophone] = useState(false)
    let mainAudio = useAudioLevel();

    // const soundlottie = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: animationDatas,
    //     rendererSettings: {
    //         preserveAspectRatio: "xMidYMid slice",
    //     },
    // };

    const onMicrophone = () => {
        setOnOffMicrophone(true)
        mainAudio(true);
    }

    const offmicrophone = () => {
        setOnOffMicrophone(false)
        mainAudio(false);
    }

    return(
        <div className="foraudio-files">
            {/* <p className="soundmeter-heading">Sound Meter</p> */}
            <div id="soundLevel"></div> 
            <div className="audio-bars">
                <canvas id="meter" style={{ width: '110%', height: '100px',marginTop: '1%' }}></canvas>
                {/* <canvas id="meter" style={{ width: '1px', height: '50px',marginTop: '10%', visibility: 'hidden' }}></canvas> */}
                {/* {onoffmicrophone == true ? 
                <div className="sound-lottie">
                    <Lottie 
                    options={soundlottie} />
                </div>
                :
                null
                } */}
                {onoffmicrophone == false ?
                <CImage src={Mutedmic} onClick={onMicrophone} className="mic-image"/>
                :
                <CImage src={Micimg} onClick={offmicrophone} className="mic-image"/>
                }
            </div>
   

            <div className="audiobar-setting">
                {/* <div className="startbutton-audiometer">
                    {onoffmicrophone == false ?
                    // <button onClick={onMicrophone} style={{width: '70px',height: '30px'}}>Start</button>
                    <CImage src={Mutedmic} onClick={onMicrophone} className="mic-image"/>
                    :
                    // <button onClick={offmicrophone} style={{width: '70px',height: '30px'}}>Stop</button>
                    <CImage src={Micimg} onClick={offmicrophone} className="mic-image"/>
                    } 
                </div> */}
                <p className="soundmeter-heading">#SHORon</p>
            </div>
        </div>
    )
}
export default Audiofile;