import React from "react";
import { useState,useEffect } from "react";
import './fanemote.css'
import { CImage } from "@coreui/react";
import Close from'../images/close.png';

function Text() {

    const [closetext , setClosetext] = useState(true);

    const onChangestate = () => {
        setClosetext(false);
    }

    return(
        <div className="text">
            {closetext == true ?
            <div className="formaintext-div">
                <p className="maintext-style"><strong>Dil Ki Baath! &nbsp;</strong><br/>Place your finger over the Camera and make sure a medium-dark red rectangle is seen. Ensure ambient light and cheer for your favourite team</p>
                <CImage src={Close} className="close-image" onClick={onChangestate} />
            </div>
             :
             null
             }
        </div>
    )
}
export default Text;