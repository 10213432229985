import { initializeApp } from "firebase/app";
// import firebase from 'firebase/compat/app'
// import 'firebase/compat/auth';
// import "firebase/auth";


// for dev
// const firebaseConfig = initializeApp({
//   apiKey: "AIzaSyAeK_w-uGh3piXPxjOU0nCkWsaF30cIeYY",
//   authDomain: "fanplay-dev.firebaseapp.com",
//   databaseURL: "https://fanplay-dev.firebaseio.com",
//   projectId: "fanplay-dev",
//   storageBucket: "fanplay-dev.appspot.com",
//   messagingSenderId: "187705283249",
//   appId: "1:187705283249:web:6d018c8073f48c10128890",
//   measurementId: "G-F5MP5WV173"
// });

//for prod
const firebaseConfig = initializeApp({
  apiKey: "AIzaSyCx82qzkDgmzpzzPsD3YfMni0VXhd9pFL8",
  authDomain: "fanplay-iot.firebaseapp.com",
  databaseURL: "https://fanplay-iot.firebaseio.com",
  projectId: "fanplay-iot",
  storageBucket: "fanplay-iot.appspot.com",
  messagingSenderId: "699544504112",
  appId: "1:699544504112:web:c052cb969e9a1d06526af5",
  measurementId: "G-Z1PR0Q3K24"
});

export default firebaseConfig;

