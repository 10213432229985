//dev
// export const url = "https://fanplaygurudevapi.azurewebsites.net";

//prod
export const url = "https://fanplayguru-prod.azurewebsites.net";

//dev
// export const teamId = 116;

//prod
export const teamId = 89;


//dev
// export const clientid = 8;

//prod
export const clientid = 3;



//dev
// export const sportid = 1;

//prod
export const sportid = 1;

//dev
// export const tournamentid = 56;

//prod
export const tournamentid = 29;

export const refreshAPIRate = 15000;

export const LEADERBOARD_DURATION = 604800;

export const From = "2024-01-15";

export const To = "2024-02-19";
