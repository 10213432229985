import React from "react";
import './fanemote.css';
import { useContext , useEffect , useRef, useState } from "react";
import { AuthContext } from "../Auth/Auth";
import { FanEngageContext } from "../Auth/fanengage";
// import Cameraicon from '../images/camerimage.png';
import { CImage } from "@coreui/react";
import ReactSpeedometer from "react-d3-speedometer";
// import Heartrecording from '../images/hrrecordimg.png';
// import Heartinmeter from '../images/heartinmeter.png'
import Hrrec from '../images/newhrrec.png';
// import useAudioLevel from "../Hook/useAudioLevel";
import Ecgline from '../images/ecgline.png';
import useCameraHR from "../Hook/useCameraHR";
  
function CameraHR({setLoopforheart}) {

  const { token, usersid,userTeam } = useContext(AuthContext);
  const { updateHeartRate, onHRInterval , selectedteam} = useContext(FanEngageContext);
  const [ ready, setReady ] = useState(false);
  const [hrspeedometer , setHrspeedometer] = useState('')
  // let mainAudio = useAudioLevel("","");
  let camerahr = useCameraHR('user')

  const hrRef = useRef(null);


  
// const segmentValueFormatter = (value) => {
//   if (value < 60) {
//     return `${value} 😒`
//   }
//   if (value < 120) {
//     return `${value} 😐`
//   }
//   if (value < 180) {
//     return `${value} 😌`
//   }
//   if (value < 240) {
//     return `${value} 😊`
//   }
//   if (value < 300) {
//     return `${value} 😉`
//   }

//   return `${value} 😇`
// }



  useEffect(() => {
    let timer = null;
    camerahr();
    // mainAudio();
    if(timer){
      clearInterval(timer);
    }
      timer = setInterval(() => {
        // Heart rate sampling for each second
        if (hrRef.current?.innerHTML && !isNaN(+hrRef.current.innerHTML)) {
          let hr = +hrRef.current.innerHTML;
          setHrspeedometer(hr);
          setReady(true);
          updateHeartRate(hr)
        }
      }, 1000);
    
    return () => {
      clearInterval(timer);
    }
  }, []);


  useEffect(() => {
    let timer = null;
    let totimeout = null;
    if(timer){
      clearInterval(timer);
    }
    if (usersid > 0 && usersid != null) {      
      // 30 sec interval post the average heartrate
      timer = setInterval(() => {
        if (hrRef.current?.innerHTML && !isNaN(+hrRef.current.innerHTML)) {
          // let hr = +hrRef.current.innerHTML;
          // setReady(true);
          onHRInterval(usersid, selectedteam)
          setLoopforheart(true);
          totimeout = setTimeout(() => {
            setLoopforheart(false);
          },4000)
        }
      }, 30000);
    }

    return () => {
      clearInterval(timer);
    }
  }, [usersid, token , selectedteam]);


  return (
    <div className="heartrate-recording-session">
      <div style={{height:'157px' , marginLeft:'0%' , marginTop:'4%'}}>
        <ReactSpeedometer
        value={hrspeedometer >= 160 ? 160 : hrspeedometer}
        maxValue={200}
        segments={5}
        // segmentValueFormatter={segmentValueFormatter}
        segmentColors={[
          '#74C64E',
          '#A0E252',
          '#F2F21C',
          '#F2A60F',
          '#F22D06'
        ]}
        ringWidth={10}
        needleColor="#000000"
        textColor="#FFFFFF"
        height={95}
        width={155}
        className="speedometer-setting"
        />
        <div className="entire-hrsession">
            <div ref={hrRef} id="heartrate">Calibrating...</div>
            { ready && <div id="heartrateses"> bpm</div>}
          </div>
          { ready && <div id="heartrates"><p>#DilKiBaat</p></div>}
          {/* <div style={{width:'0px' , marginLeft:'40%' , marginTop:'0%'}} >
            <canvas id="meter" style={{width: '146px', height: '50px'}}></canvas>
          </div> */}
          <canvas id="output" style={{ display: 'inline-flex' ,height: '33px' , width: '36px' , marginTop: '20px', marginLeft:'1%'  }} />
          <CImage src={Hrrec} className="forlove-image" />
      </div>
      <CImage src={Ecgline} className="forecg-image" />


      {/* <table style={{ width: '100%' }}>
        <tbody><tc>
          <br/>
          <td>
            <canvas id="output" style={{ display: 'inline-flex' ,height: '1px' , width: '0px' , visibility: 'hidden' }} />
          </td>
        </tc>
        </tbody></table> */}
        {/* <div style={{width:'0px'}}>
          <canvas id="output" style={{ display: 'inline-flex' ,height: '132px' , width: '146px' , marginTop: '20px', marginLeft:'1%'  }} />
          <CImage src={Hrrec} className="hrrecording-imag"/>
        </div> */}
      <video hidden playsInline autoPlay />
      {/* <div className="recording-image">
            <CImage src={Cameraicon} className="hr-recording-img"/>
        </div> */}
    </div>
  )
}

export default CameraHR;